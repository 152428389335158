export class SortableList {

    /**
     * Set up the constructor
     * @param {HTMLElement} list The list element to be made sortable
     * @param {Object} options Config options for this sortable list
     */
    constructor(list = null, options = {}) {
        // Make sure there's a sortable list element defined
        if (!list) return;

        this.list = list;
        this.listLength = 0;

        // Merge user options into defaults
        this.settings = Object.assign({}, this.defaults, options);

        if (this.settings.liveRegion !== '') {
            this.liveRegion = document.querySelector(this.settings.liveRegion);
        }

        this.listItems = this.list.children;

        // Add event listeners for sortable items
        Array.prototype.forEach.call(this.listItems, function(item, index) {
            this.listLength++;
            this.listItemControllers[index] = new AbortController();

            if (this.settings.dragHandle !== '') {
                item.querySelector(this.settings.dragHandle).addEventListener('mousedown', this.mouseDownHandler.bind(this), { signal: this.listItemControllers[index].signal });
                item.querySelector(this.settings.dragHandle).addEventListener('touchstart', this.touchStartHandler.bind(this), { signal: this.listItemControllers[index].signal });
                item.querySelector(this.settings.dragHandle).addEventListener('keydown', this.keyDownHandler.bind(this), { signal: this.listItemControllers[index].signal });
            } else {
                item.addEventListener('mousedown', this.mouseDownHandler.bind(this), { signal: this.listItemControllers[index].signal });
                item.addEventListener('touchstart', this.touchStartHandler.bind(this), { signal: this.listItemControllers[index].signal });
                item.classList.add('cursor-move');
                item.addEventListener('keydown', this.keyDownHandler.bind(this), { signal: this.listItemControllers[index].signal });
            }
        }, this);

        // Create a new AbortController
        this.touchController = new AbortController();
        document.addEventListener('touchmove', this.touchMoveHandler.bind(this), { signal: this.touchController.signal });
        document.addEventListener('touchend', this.touchEndHandler.bind(this), { signal: this.touchController.signal });
    }

    // Variables
    settings;

    list;

    // nodelist of sortable list items
    listItems;

    // The element currently being dragged
    draggingElement;

    // Current mouse position relative to dragging element
    x = 0;
    y = 0;

    // Starting touch position
    startTouchX = 0;
    startTouchY = 0;

    // Value of element currently being dragged
    draggingValue;

    // Index of element currently being dragged (zero-based)
    draggingIndex;

    // Total length of sortable list
    listLength;

    // placeholder
    placeholder;

    // Live region for announcements
    liveRegion;

    // Dragging/moving flags
    isDraggingStarted = false;
    moving = false;

    // AbortController used to remove all added event listeners when moving stops
    mouseController;
    touchController;
    listItemControllers = [];

    defaults = {
        activeItemClass: [],
        placeholderClass: [],
        dragHandle: '',
        liveRegion: ''
    };

    // Methods

    // Swap two nodes
    swap(nodeA, nodeB) {
        const parentA = nodeA.parentNode;
        const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;

        // Move `nodeA` to before the `nodeB`
        nodeB.parentNode.insertBefore(nodeA, nodeB);

        // Move `nodeB` to before the sibling of `nodeA`
        parentA.insertBefore(nodeB, siblingA);
    }

    isAbove(nodeA, nodeB) {
        // Get bounding rectangle of nodes
        const rectA = nodeA.getBoundingClientRect();
        const rectB = nodeB.getBoundingClientRect();

        return (rectA.top + rectA.height / 2 < rectB.top + rectB.height / 2);
    }

    addClasses(element, classes) {
        classes.forEach(function(className) {
            element.classList.add(className);
        });
    }

    removeClasses(element, classes) {
        classes.forEach(function(className) {
            element.classList.remove(className);
        });
    }

    setDraggingElement(ev) {
        if (this.settings.dragHandle !== '' || !ev.target.matches('[data-sortable]')) {
            this.draggingElement = ev.target.closest('[data-sortable]');
        } else {
            this.draggingElement = ev.target;
        }
    }

    resetDraggingElementStyle() {
        this.draggingElement.style.removeProperty('top');
        this.draggingElement.style.removeProperty('left');
        this.draggingElement.style.removeProperty('width');
        this.draggingElement.style.removeProperty('position');
    }

    mouseDownHandler(ev) {
        this.setDraggingElement(ev);

        if (this.settings.activeItemClass.length) {
            this.addClasses(this.draggingElement, this.settings.activeItemClass);
        }

        // Calculate mouse position
        const rect = this.draggingElement.getBoundingClientRect();
        this.x = rect.left;
        this.y = rect.top;

        // Create a new AbortController
        this.mouseController = new AbortController();

        // Attach listeners to document
        document.addEventListener('mousemove', this.mouseMoveHandler.bind(this), { signal: this.mouseController.signal });
        document.addEventListener('mouseup', this.mouseUpHandler.bind(this), { signal: this.mouseController.signal });

        // Prevent selections whilst dragging
        document.querySelector('body').classList.add('select-none');
    }

    mouseMoveHandler(ev) {
        const draggingRect = this.draggingElement.getBoundingClientRect();

        if (!this.isDraggingStarted) {
            // Update flag
            this.isDraggingStarted = true;

            // Let placeholder take height of dragging element so next element won't move up
            let elementType = this.draggingElement.nodeName;
            this.placeholder = document.createElement(elementType);
            if (this.settings.placeholderClass.length) {
                this.addClasses(this.placeholder, this.settings.placeholderClass);
            }
            this.draggingElement.parentNode.insertBefore(
                this.placeholder,
                this.draggingElement.nextSibling
            );

            // Set placeholder height
            this.placeholder.style.height = `${draggingRect.height}px`;
        }

        // Set position for dragging element
        this.y = this.y + ev.movementY;
        this.x = this.x + ev.movementX;
        this.draggingElement.style.position = 'fixed';
        this.draggingElement.style.top = `${this.y}px`;
        this.draggingElement.style.left = `${this.x}px`;
        this.draggingElement.style.width = `${draggingRect.width}px`;

        // The current order:
        // prevEle
        // draggingElement
        // placeholder
        // nextEle
        const prevEle = this.draggingElement.previousElementSibling;
        const nextEle = this.placeholder.nextElementSibling;

        // The dragging element is above the previous element
        // User moves the dragging element to the top
        if (prevEle && this.isAbove(this.draggingElement, prevEle)) {
            // The current order    -> The new order
            // prevEle              -> placeholder
            // draggingElement      -> draggingElement
            // placeholder          -> prevEle
            this.swap(this.placeholder, this.draggingElement);
            this.swap(this.placeholder, prevEle);
            return;
        }

        // The dragging element is below the next element
        // User moves the dragging element to the bottom
        if (nextEle && this.isAbove(nextEle, this.draggingElement)) {
            // The current order    -> The new order
            // draggingElement      -> nextEle
            // placeholder          -> placeholder
            // nextEle              -> draggingElement
            this.swap(nextEle, this.placeholder);
            this.swap(nextEle, this.draggingElement);
        }
    }

    mouseUpHandler(ev) {
        // Remove placeholder
        this.placeholder && this.placeholder.parentNode.removeChild(this.placeholder);

        // Reset flag
        this.isDraggingStarted = false;

        if (this.settings.activeItemClass.length) {
            this.removeClasses(this.draggingElement, this.settings.activeItemClass);
        }

        // Remove select-none class from list element
        // draggingElement.closest('[data-sortable-list]').classList.remove('select-none');
        document.querySelector('body').classList.remove('select-none');

        // Remove the position styles
        this.resetDraggingElementStyle();

        this.x = null;
        this.y = null;
        this.draggingElement = null;

        // Remove mousemove and mouseup handlers
        this.mouseController.abort();
        // document.removeEventListener('mousemove', this.mouseMoveHandler.bind(this));
        // document.removeEventListener('mouseup', this.mouseUpHandler.bind(this));
    }

    announce(message) {
        let announcement = document.createElement('div');
        announcement.innerText = message;

        this.liveRegion.appendChild(announcement);

        setTimeout(function() {
            this.liveRegion.removeChild(announcement);
        }.bind(this), 5000);
    }

    keyDownHandler(ev) {

        switch (ev.keyCode) {

            // Return or Space
            case 13:
            case 32:
                ev.preventDefault();
                ev.stopPropagation();

                if (this.moving) {
                    this.moving = !this.moving;
                    if (this.settings.activeItemClass.length) {
                        this.removeClasses(this.draggingElement, this.settings.activeItemClass);
                    }
                    this.removeClasses(this.draggingElement, ['relative']);
                    if (this.liveRegion) {
                        this.announce(this.draggingValue + ' dropped');
                    }
                    this.listItems = this.list.children;
                } else {
                    this.moving = !this.moving;
                    this.setDraggingElement(ev);

                    this.draggingValue = this.draggingElement.dataset.sortableValue;
                    this.listItems = this.list.children;
                    this.draggingIndex = Array.prototype.indexOf.call(this.listItems, this.draggingElement) + 1;
                    if (this.settings.activeItemClass.length) {
                        this.addClasses(this.draggingElement, this.settings.activeItemClass);
                    }
                    this.addClasses(this.draggingElement, ['relative']);
                    if (this.liveRegion) {
                        this.announce(this.draggingValue + ' grabbed');
                    }
                }
                break;

            // Up
            case 38:
                ev.preventDefault();
                ev.stopPropagation();

                if (this.moving) {
                    const prevEle = this.draggingElement.previousElementSibling;

                    if (prevEle) {
                        this.swap(this.draggingElement, prevEle);
                        ev.target.focus();

                        this.draggingIndex--;

                        if (this.liveRegion) {
                            this.announce('The list has been reordered. ' + this.draggingValue + ' is now item ' + this.draggingIndex + ' of ' + this.listLength);
                        }
                    }
                }
                break;

            // Down
            case 40:
                ev.preventDefault();
                ev.stopPropagation();

                if (this.moving) {
                    const nextEle = this.draggingElement.nextElementSibling;

                    if (nextEle) {
                        this.swap(this.draggingElement, nextEle);
                        ev.target.focus();

                        this.draggingIndex++;

                        if (this.liveRegion) {
                            this.announce('The list has been reordered. ' + this.draggingValue + ' is now item ' + this.draggingIndex + ' of ' + this.listLength);
                        }
                    }
                }
                break;
        }
    }

    touchStartHandler(ev) {
        // Only proceed if there is one touch present
        if (!(ev && !ev.defaultPrevented && ev.touches && ev.touches.length < 2)) {
            return;
        }

        ev.preventDefault();

        this.setDraggingElement(ev);

        if (this.settings.activeItemClass.length) {
            this.addClasses(this.draggingElement, this.settings.activeItemClass);
        }

        if (!this.isDraggingStarted) {
            // Store starting touch X and Y
            this.startTouchX = ev.touches[0].clientX;
            this.startTouchY = ev.touches[0].clientY;
        }

        // Calculate mouse position
        const rect = this.draggingElement.getBoundingClientRect();
        this.x = rect.left;
        this.y = rect.top;

        // Prevent selections whilst dragging
        document.querySelector('body').classList.add('select-none');
    }

    touchMoveHandler(ev) {
        // Only proceed if there is one touch present
        if (!(ev && !ev.defaultPrevented && ev.touches && ev.touches.length < 2)) {
            return;
        }

        if (this.draggingElement) {
            const draggingRect = this.draggingElement.getBoundingClientRect();

            if (!this.isDraggingStarted) {
                // Update flag
                this.isDraggingStarted = true;

                // Let placeholder take height of dragging element so next element won't move up
                let elementType = this.draggingElement.nodeName;
                this.placeholder = document.createElement(elementType);
                if (this.settings.placeholderClass.length) {
                    this.addClasses(this.placeholder, this.settings.placeholderClass);
                }
                this.draggingElement.parentNode.insertBefore(
                    this.placeholder,
                    this.draggingElement.nextSibling
                );

                // Set placeholder height
                this.placeholder.style.height = `${draggingRect.height}px`;

            }

            // Set position for dragging element
            this.draggingElement.style.position = 'fixed';
            this.draggingElement.style.left = `${this.x + ev.targetTouches[0].clientX - this.startTouchX}px`;
            this.draggingElement.style.top = `${this.y + ev.targetTouches[0].clientY - this.startTouchY}px`;
            this.draggingElement.style.width = `${draggingRect.width}px`;

            // The current order:
            // prevEle
            // draggingElement
            // placeholder
            // nextEle
            const prevEle = this.draggingElement.previousElementSibling;
            const nextEle = this.placeholder.nextElementSibling;

            // The dragging element is above the previous element
            // User moves the dragging element to the top
            if (prevEle && this.isAbove(this.draggingElement, prevEle)) {
                // The current order    -> The new order
                // prevEle              -> placeholder
                // draggingElement      -> draggingElement
                // placeholder          -> prevEle
                this.swap(this.placeholder, this.draggingElement);
                this.swap(this.placeholder, prevEle);
                return;
            }

            // The dragging element is below the next element
            // User moves the dragging element to the bottom
            if (nextEle && this.isAbove(nextEle, this.draggingElement)) {
                // The current order    -> The new order
                // draggingElement      -> nextEle
                // placeholder          -> placeholder
                // nextEle              -> draggingElement
                this.swap(nextEle, this.placeholder);
                this.swap(nextEle, this.draggingElement);
            }
        }
    }

    touchEndHandler(ev) {
        // Only proceed if there is one touch present
        if (!(ev && !ev.defaultPrevented && ev.touches && ev.touches.length < 2)) {
            return;
        }

        if (this.draggingElement && this.isDraggingStarted) {
            ev.preventDefault();

            // Remove placeholder
            this.placeholder && this.placeholder.parentNode.removeChild(this.placeholder);

            // Reset flag
            this.isDraggingStarted = false;

            if (this.settings.activeItemClass.length) {
                this.removeClasses(this.draggingElement, this.settings.activeItemClass);
            }

            // Remove select-none class from list element
            // draggingElement.closest('[data-sortable-list]').classList.remove('select-none');
            document.querySelector('body').classList.remove('select-none');

            // Remove the position styles
            this.resetDraggingElementStyle();

            this.x = null;
            this.y = null;
            this.draggingElement = null;
        }
    }

        /**
         * Destroy the current initialization
         */
        destroy() {
            // Make sure plugin has been initialized
            if (!this.list) return;

            // Remove all event listeners
            Array.prototype.forEach.call(this.listItems, function(item, index) {
                if (index in this.listItemControllers) {
                    this.listItemControllers[index].abort();
                }
                // this.listItemControllers[index].abort();
                if (this.settings.dragHandle !== '') {
                    // item.querySelector(this.settings.dragHandle).removeEventListener('mousedown', this.mouseDownHandler.bind(this));
                    // item.querySelector(this.settings.dragHandle).removeEventListener('touchstart', this.touchStartHandler.bind(this));
                    // item.querySelector(this.settings.dragHandle).removeEventListener('keydown', this.keyDownHandler.bind(this));
                } else {
                    // item.removeEventListener('mousedown', this.mouseDownHandler.bind(this));
                    // item.removeEventListener('touchstart', this.touchStartHandler.bind(this));
                    item.classList.remove('cursor-move');
                    // item.removeEventListener('keydown', this.keyDownHandler.bind(this));
                }
            }, this);

            // document.removeEventListener('touchmove', this.touchMoveHandler.bind(this));
            // document.removeEventListener('touchend', this.touchEndHandler.bind(this));
            this.touchController.abort();

            // Reset variables
            this.list = null;
            this.listItems = null;
            this.draggingElement = null;
            this.draggingIndex = null;
            this.draggingValue = null;
            this.listLength = null;
            this.placeholder = null;
            this.liveRegion = null;
            this.isDraggingStarted = false;
            this.moving = false;
        }
}
